/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Outlet,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { RKTheme } from '@robinpowered/design-system';
import { Header } from '@robinpowered/dashboard-apps-header';
import { cssReset } from './cssReset';
import { config } from 'config';
import {
  useAmplitude,
  useAuthContext,
  AmplitudeProvider,
  ApolloContextProvider,
  AuthContextProvider,
  RecoilURLSyncJSONSafe,
  MapControlsProvider,
} from 'contexts';
import { i18n } from 'i18n';
import { GlobalErrorBoundary } from 'components/global/GlobalErrorBoundary';
import { Map } from 'components/map';
import { datadogRum } from '@datadog/browser-rum';
import { datadogOrigins } from 'ddConfig';
import { RecoilRoot } from 'recoil';
import { LocalStorageProvider } from 'contexts/LocalStorageContext';
import { ClockProvider } from 'hooks/useClock';
import styled from '@emotion/styled';
import { MapControls } from 'components/map/controls/MapControls';
import { DashboardLayout } from 'components/layout';
import { RobinLoader } from 'components/global/RobinLoader';
import { ConfigProvider, Layout, RobinTheme } from '@robinpowered/ui-kit';
import { LeftSidebar, RightSidebar } from 'components/sidebar';
import { LegacyOfficeRedirect } from 'components/global/LegacyOfficeRedirect';
import { MessageConfig } from 'components/global/MessageConfig';
import { FilterControls } from 'components/map/filters';
import { Z_INDEX } from './constants';
import { OfficeTheme } from 'officeTheme';

export const App = (): JSX.Element => {
  useEffect(() => {
    datadogRum.init({
      applicationId: config.ddApplicationId,
      clientToken: config.ddClientToken,
      site: config.ddSite,
      service: 'office-ui',
      env: config.robinEnv,
      allowedTracingUrls: datadogOrigins,
      traceSampleRate: 100, //Default
      version: config.appVersion,
      sessionSampleRate: 100, //Default
      sessionReplaySampleRate: 0, //Default - adding in case we want to start using
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      useCrossSiteSessionCookie: true,
    });

    return;
  }, []);

  return (
    <ConfigProvider
      theme={{
        ...RobinTheme,
        cssVar: { key: 'robin-theme' },
        ...OfficeTheme,
      }}
    >
      <Suspense fallback={<RobinLoader />}>
        <I18nextProvider i18n={i18n}>
          <RecoilRoot>
            <RecoilURLSyncJSONSafe>
              <GlobalErrorBoundary>
                <Global styles={cssReset} />
                <RKTheme>
                  <BrowserRouter>
                    <ApolloContextProvider>
                      <AuthContextProvider>
                        <AmplitudeProvider>
                          <HelmetProvider>
                            <DocumentHead />
                            <RootRouteTree />
                          </HelmetProvider>
                        </AmplitudeProvider>
                      </AuthContextProvider>
                    </ApolloContextProvider>
                  </BrowserRouter>
                </RKTheme>
              </GlobalErrorBoundary>
            </RecoilURLSyncJSONSafe>
          </RecoilRoot>
        </I18nextProvider>
      </Suspense>
    </ConfigProvider>
  );
};

const RootRouteTree = (): JSX.Element => {
  const { t } = useTranslation('error');

  return (
    <Routes>
      <Route path="/" element={<span>{t('missing_slug')}</span>} />
      <Route
        path="/"
        element={
          <DashboardLayout>
            <HeaderWithParam />
            <Outlet />
          </DashboardLayout>
        }
      >
        <Route path=":orgSlug/officeV2">
          <Route
            index
            element={
              <LocalStorageProvider>
                <LegacyOfficeRedirect>
                  <MapControlsProvider>
                    <ClockProvider>
                      <Layout>
                        <MessageConfig />

                        <AppContainer>
                          <LeftSidebar />

                          <MainContainer>
                            <MapControls />
                            <FilterControls />
                            <Map />
                          </MainContainer>

                          <RightSidebar />
                        </AppContainer>
                      </Layout>
                    </ClockProvider>
                  </MapControlsProvider>
                </LegacyOfficeRedirect>
              </LocalStorageProvider>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

const HeaderWithParam = ({ headless }: { headless?: boolean }): JSX.Element => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const { trackEvent } = useAmplitude();
  const { setOrgSlug } = useAuthContext();

  useEffect(() => {
    setOrgSlug(orgSlug);
  }, [orgSlug, setOrgSlug]);

  return (
    <>
      {!headless && (
        <Header
          activeOrgSlug={orgSlug ?? ''}
          amplitudeEventHandler={trackEvent}
        />
      )}
    </>
  );
};

export const DocumentHead = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Helmet>
      <title>{t('default_page_title')}</title>
    </Helmet>
  );
};

const AppContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  position: relative;
  z-index: ${Z_INDEX.APP};
`;

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
