import common from './common.json';
import leftSideBar from './leftSideBar.json';
import resourceDetails from './resourceDetails.json';
import filters from './filters.json';
import map from './map.json';
import mapControls from './mapControls.json';
import time from './time.json';
import spaceResourceDetails from './spaceResourceDetails.json';
import error from './error.json';

export const en = {
  common,
  resourceDetails,
  leftSideBar,
  filters,
  map,
  mapControls,
  time,
  spaceResourceDetails,
  error,
};
