import { useApolloContext } from '../contexts';
import { config } from '../config';
import { useAccessToken } from './useAccessToken';
import { useFeatureFlag as commonUseFeatureFlag } from '@robinpowered/dashboard-apps-common';

export type FeatureFlag =
  | 'talos(office-V2)'
  | 'talos(office-V2-shared-assigned-desks)';

// wrapper around feature flag hook
export const useFeatureFlag = (flag: FeatureFlag, defaultValue = false) => {
  const url = `${config.featureFlagUrl}/api/web-flags`;
  const accessToken = useAccessToken();
  const { tenantId } = useApolloContext();

  const { value, error, loading } = commonUseFeatureFlag<boolean>({
    serviceUrl: url,
    accessToken,
    flag: flag,
    defaultValue,
    tenantId: tenantId || null,
  });

  return {
    value,
    error,
    loading,
  };
};
